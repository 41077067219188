import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import Script from "next/script";
import { AuthWrapper } from "../components/AuthWrapper";
import "../styles/global.css";
// Import stripe as a side effect, recommended by Stripe
import "@stripe/stripe-js";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import { useRouter } from "next/router";

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
  if (!posthogKey) throw new Error("Missing PostHog key");
  posthog.init(posthogKey, {
    api_host: "https://app.posthog.com",
    // Disable in development
    loaded: (posthog) => {
      // eslint-disable-next-line turbo/no-undeclared-env-vars
      if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
        console.log("PostHog disabled in development");
        posthog.opt_out_capturing();
      }
    },
  });
}

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-JYZ0HDSK1K"
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'G-JYZ0HDSK1K');`,
        }}
      />
      <PostHogProvider client={posthog}>
        <SessionProvider session={session}>
          {
            // @ts-ignore
            Component.auth ? (
              <AuthWrapper>
                <Component {...pageProps} />
              </AuthWrapper>
            ) : (
              <Component {...pageProps} />
            )
          }
        </SessionProvider>
      </PostHogProvider>
    </>
  );
}
