import Link from "next/link";
import { ReactNode } from "react";
import { useSession } from "next-auth/react";
import Router from "next/router";
import { Toaster } from "react-hot-toast";
import {
  Book,
  DiscordLogo,
  FolderOpen,
  House,
  IconProps,
  Person,
  User,
} from "phosphor-react";
export function AuthWrapper({ children }: { children: ReactNode }) {
  useSession({
    required: true,
    onUnauthenticated() {
      Router.push("/sign-in");
    },
  });
  return (
    <div>
      <header className="p-4 md:p-8">
        <nav className="flex gap-4 md:gap-8">
          <PolymorphicNavLink element={Link} href="/projects" icon={FolderOpen}>
            Projects
          </PolymorphicNavLink>
          <PolymorphicNavLink element={Link} href="/account" icon={User}>
            Account
          </PolymorphicNavLink>
          {/* <PolymorphicNavLink
            href="https://tonerow.notion.site/Spreadsheet-page-Documentation-8546110470ce46899e1de455dc456cd8"
            target="_blank"
            rel="noopener noreferrer"
            icon={Book}
          >
            Documentation
          </PolymorphicNavLink> */}
          <PolymorphicNavLink
            href="https://discord.gg/7hYrMYzS45"
            target="_blank"
            rel="noopener noreferrer"
            icon={DiscordLogo}
          >
            Discord
          </PolymorphicNavLink>
        </nav>
      </header>
      {children}
      <Toaster position="top-right" reverseOrder={true} />
    </div>
  );
}

function PolymorphicNavLink<T extends React.ElementType = "a">({
  children,
  icon,
  element,
  className = "",
  ...props
}: {
  children: ReactNode;
  icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  element?: T;
} & React.ComponentPropsWithoutRef<T>) {
  const Component = element ?? "a";
  const Icon = icon;
  return (
    <Component
      {...props}
      className={`flex opacity-60 hover:opacity-100 gap-2 ${className}`}
    >
      <Icon className="w-6 h-6 md:w-5 md:h-5" weight="fill" />
      <span className="hidden md:inline">{children}</span>
    </Component>
  );
}
